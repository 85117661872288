<template>
	<div class="register-page">
		<user-card title="业委会注册">
			<a-form
				id="register-form"
				:form="form"
				class="register-form"
				@submit="handleSubmit"
			>
				<a-form-item>
					<a-cascader
						size="large"
						v-decorator="['address']"
						:field-names="{
							label: 'name',
							value: 'adcode',
							children: 'districts',
						}"
						:options="cascaderOptions"
						placeholder="地址"
					/>
				</a-form-item>
				<a-form-item>
					<a-input
						size="large"
						v-decorator="[
							'communityName',
							{
								rules: [],
							},
						]"
						placeholder="社区名称"
					></a-input>
				</a-form-item>
				<a-form-item>
					<a-input
						size="large"
						v-decorator="[
							'UOCName',
							{
								rules: [
									{
										required: true,
										message: '业委会名称不能为空',
									},
								],
							},
						]"
						placeholder="业委会名称"
					></a-input>
				</a-form-item>
				<a-form-item>
					<a-input
						size="large"
						v-decorator="[
							'userName',
							{
								rules: [
									{
										required: true,
										message: '用户姓名不能为空',
									},
								],
							},
						]"
						placeholder="用户姓名"
					></a-input>
				</a-form-item>
				<a-form-item>
					<a-input
						size="large"
						v-decorator="[
							'pwd',
							{
								rules: [
									{
										required: true,
										message: '密码不能为空',
									},
								],
							},
						]"
						placeholder="6 - 16位密码，区分大小写"
						type="password"
					></a-input>
				</a-form-item>
				<a-form-item>
					<a-input
						size="large"
						v-decorator="[
							'confirm',
							{
								rules: [
									{
										required: true,
										message: '确认密码不能为空',
									},
									{
										validator: compareToFirstPassword,
									},
								],
							},
						]"
						placeholder="确认密码"
						type="password"
					></a-input>
				</a-form-item>
				<a-form-item>
					<a-input
						size="large"
						@change="setPhoneNum"
						v-decorator="[
							'phone',
							{
								rules: [
									{
										required: true,
										message: '手机号不能为空',
									},
								],
							},
						]"
						placeholder="请输入手机号（用作登录账号）"
					></a-input>
				</a-form-item>
				<a-form-item>
					<a-row :gutter="20">
						<a-col :span="16">
							<a-input
								size="large"
								v-decorator="[
									'code',
									{
										rules: [
											{
												required: true,
												message: '验证码不能为空',
											},
										],
									},
								]"
								placeholder="验证码"
							></a-input>
						</a-col>
						<a-col :span="8">
							<send-sms :phoneNum="phoneNum"></send-sms>
						</a-col>
					</a-row>
				</a-form-item>
				<a-form-item>
					<uploadFile
						v-decorator="[
							'UOCRecordFile',
							{
								getValueFromEvent: uploadNormFile,
							},
						]"
						:item="{
							props: 'UOCRecordFile',
							uploadText: '业委会备案信息',
							maxUpload: 1,
						}"
						:form="form"
					></uploadFile>
				</a-form-item>
				<a-form-item>
					<a-button
						size="large"
						type="primary"
						html-type="submit"
						class="register-form-button"
						:loading="loading"
						>业委会注册</a-button
					>
				</a-form-item>
			</a-form>
		</user-card>
	</div>
</template>

<script>
import userCard from '@/components/user/card'
import sendSms from '@/components/user/sendSms'
import uploadFile from '@/components/zform/components/zossUpload.vue'
import { getStreetList, registered } from '@/api/user/register'
import md5 from 'js-md5'

export default {
	name: '',
	data() {
		return {
			cascaderOptions: [],
			loading: false,
			phoneNum: null,
		}
	},
	methods: {
		uploadNormFile(e) {
			if (Array.isArray(e)) {
				return e
			}
			return e && e.fileList
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (err) {
					return
				}
				console.log(values)
				this.loading = true
				try {
					let {
						UOCName,
						address,
						code,
						neighborhoodCommitteeName,
						phone,
						pwd,
						userName,
						UOCRecordFile,
						communityName
					} = values
					let params = {
						UOCName,
						captcha: md5(code),
						phone,
						pwd: md5(pwd),
						userName,
						streetId: address,
						UOCRecordFile,
						communityName
					}

					registered(params)
						.then(() => {
							this.loading = false
							this.$message.success('注册成功！请等待管理员审核！', 15)
						})
						.catch((err) => {
							console.log(err);
							this.$message.error('验证码错误')
							this.loading = false
						})
				} catch (error) {
					console.log(error)
				}
			})
		},
		compareToFirstPassword(rule, value, callback) {
			const form = this.form
			if (value && value !== form.getFieldValue('pwd')) {
				callback('两次密码校验不一致')
			} else {
				callback()
			}
		},
		setPhoneNum(e) {
			this.phoneNum = e.target.value
		},
	},
	components: {
		userCard,
		sendSms,
		uploadFile,
	},
	created() {
		this.form = this.$form.createForm(this)
		getStreetList().then((res) => {
			this.cascaderOptions = res.list
			console.log(this.cascaderOptions)
		})
	},
}
</script>
<style lang="scss" scoped>
.register-page {
	.register-form-button {
		width: 100%;
	}
}
</style>
