<template>
	<div class="forget-password-page">
		<user-card title="忘记密码">
			<a-form
				id="forgetPassword-form"
				:form="form"
				class="forget-password-form"
				@submit="handleSubmit"
			>
				<a-form-item>
					<a-input
						size="large"
						v-decorator="['phoneNum']"
						placeholder="手机号"
					></a-input>
				</a-form-item>
				<a-form-item>
					<a-row :gutter="20">
						<a-col :span="16">
							<a-input
								size="large"
								v-decorator="['phoneNum']"
								placeholder="验证码"
							></a-input>
						</a-col>
						<a-col :span="8">
							<a-button size="large">获取验证码</a-button>
						</a-col>
					</a-row>
				</a-form-item>
				<a-form-item>
					<a-input
						size="large"
						v-decorator="['password']"
						placeholder="6 - 16位密码，区分大小写"
						type="password"
					></a-input>
				</a-form-item>
				<a-form-item>
					<a-input
						size="large"
						v-decorator="['password']"
						placeholder="确认密码"
						type="password"
					></a-input>
				</a-form-item>
				<a-form-item>
					<a-button
						size="large"
						type="primary"
						html-type="submit"
						class="forgetp-password-form-button"
						>重置密码</a-button
					>
				</a-form-item>
			</a-form>
		</user-card>
	</div>
</template>

<script>
import userCard from '@/components/user/card'
import { updatePwd } from '@/api/user/login.js'

export default {
	name: '',
	data() {
		return {}
	},
	methods: {
		handleSubmit() {
			this.form.validateFields((err, values) => {
				if (err) {
					return
				}
        const { captcha, pwd, newPhone, newCaptcha } = values
				updatePwd({
					pwd: md5(pwd),
					captcha: md5(captcha),
					phone,
					type,
					userId: _id,
				})
					.then((res) => {
						localStorage.setItem('userInfo', JSON.stringify(res))
						setToken(res.token)
						location.reload()
					})
					.catch((err) => {
						console.log(err)
						this.$message.warning('验证码错误！')
					})
			})
		},
	},
	components: {
		userCard,
	},
	beforeCreate() {
		this.form = this.$form.createForm(this)
	},
}
</script>
<style lang="scss" scoped>
.forget-password-page {
	.forgetp-password-form-button {
		width: 100%;
	}
}
</style>
