<template>
    
</template>

<script>
import { getUserInfoWithToken } from '@/api/user/login'
import { setToken } from '@/api/auth'
export default {
    data() {
        return {
            
        }
    },
    mounted() {
        const {query} = this.$route
        if (!query.token) {
            return
        }
        console.log(this.$route);
        setToken(query.token)
        setTimeout(() => {
            getUserInfoWithToken({token: query.token}).then(res => {
                localStorage.setItem('userInfo', JSON.stringify(res))
                this.$router.push('/dashboard')
            })
        }, 0);
    },
}
</script>
