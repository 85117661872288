<template>
	<div class="login-page">
		<user-card :header="false">
			<a-tabs default-active-key="2" v-model="tabActiveKey">
				<a-tab-pane
					key="2"
					tab="手机登录"
					force-render
					class="login-pane"
				>
					<a-form
						id="login-form"
						:form="form1"
						class="login-form"
						@submit="handleSubmit2"
					>
						<a-form-item>
							<a-input
								size="large"
								@change="setPhoneNum"
								v-decorator="[
									'phone',
									{
										rules: [
											{
												required: true,
												message: '手机号不能为空',
											},
										],
									},
								]"
								placeholder="输入手机号"
							>
								<a-icon
									slot="prefix"
									type="phone"
									style="color: rgba(0, 0, 0, 0.25)"
								/>
							</a-input>
						</a-form-item>
						<a-form-item>
							<a-row :gutter="20">
								<a-col :span="16">
									<a-input
										size="large"
										v-decorator="[
											'captcha',
											{
												rules: [
													{
														required: true,
														message:
															'验证码不能为空',
													},
												],
											},
										]"
										placeholder="验证码"
									></a-input>
								</a-col>
								<a-col :span="8">
									<send-sms :phoneNum="phoneNum"></send-sms>
								</a-col>
							</a-row>
						</a-form-item>
						<a-form-item>
							<a-button
								size="large"
								type="primary"
								html-type="submit"
								class="login-form-button"
								:loading="loading"
								>登录</a-button
							>
							<div style="text-align: right">
								<span class="links-right">
									<a
										class="login-form-forgot"
										href="javascript:void(0)"
										@click="goRegister"
										>业委会注册</a
									>
								</span>
							</div>
						</a-form-item>
					</a-form>
				</a-tab-pane>
				<a-tab-pane
					
					key="1"
					tab="密码登录"
					class="login-pane"
				>
					<a-form
						id="login-form"
						:form="form"
						class="login-form"
						@submit="handleSubmit"
					>
						<a-form-item>
							<a-input
								size="large"
								v-decorator="[
									'phone',
									{
										rules: [
											{
												required: true,
												message: '账号不能为空',
											},
										],
									},
								]"
								placeholder="输入手机号"
							>
								<a-icon
									slot="prefix"
									type="user"
									style="color: rgba(0, 0, 0, 0.25)"
								/>
							</a-input>
						</a-form-item>
						<a-form-item>
							<a-input
								size="large"
								v-decorator="[
									'pwd',
									{
										rules: [
											{
												required: true,
												message: '密码不能为空',
											},
										],
									},
								]"
								type="password"
								placeholder="输入密码"
							>
								<a-icon
									slot="prefix"
									type="lock"
									style="color: rgba(0, 0, 0, 0.25)"
								/>
							</a-input>
						</a-form-item>
						<a-form-item>
							<a-button
								type="primary"
								html-type="submit"
								class="login-form-button"
								:loading="loading"
								size="large"
								>登录</a-button
							>
							<div style="text-align: right">
								<span class="links-right">
									<a
										class="login-form-forgot"
										@click="goRegister"
										href="#"
										>业委会注册</a
									>
								</span>
							</div>
						</a-form-item>
					</a-form>
				</a-tab-pane>
			</a-tabs>
		</user-card>
		<a-modal
			v-model="confirmUOCVisible"
			title="请选择一个业委会进行登录"
			@ok="handleConfirmUOCOk"
			cancelText="取消"
			okText="确定"
		>
			<a-form
				id="confirmUOC-form"
				:form="confirmUOCForm"
				class="login-form"
			>
				<a-form-item label="选择业委会">
					<a-select
						style="width: 100%"
						v-decorator="[
							'uocId',
							{
								rules: [
									{
										required: true,
										message: '请选择一个业委会！',
									},
								],
							},
						]"
					>
						<a-select-option
							v-for="item of uocList"
							:value="item._id"
							:key="item._id"
						>
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import userCard from '@/components/user/card'
import { login, getUOCWithPhone } from '@/api/user/login'
import sendSms from '@/components/user/sendSms'
import md5 from 'js-md5'
import { setToken } from '@/api/auth'
export default {
	name: '',
	data() {
		return {
			confirmUOCForm: this.$form.createForm(this),
			loading: false,
			phoneNum: null,
			typeOptions: [
				{ label: '业委会', value: 'UOC' },
				{ label: '业主', value: 'owner' },
			],
			uocId: null,
			uocList: [],
			confirmUOCVisible: false,
			tabActiveKey: '2',
		}
	},
	methods: {
		goRegister() {
			this.$router.push('/register')
		},
		jumpToForgetPassword() {
			this.$router.push('/forgetPassword')
		},
		handleConfirmUOCOk() {
			this.confirmUOCForm.validateFields((err, values) => {
				if (err) {
					return
				}
				const { uocId } = values
				this.uocId = uocId
				if (this.tabActiveKey === '1') {
					this.handleSubmit()
				} else {
					this.handleSubmit2()
				}
			})
		},
		checkUOCList(values) {
			getUOCWithPhone(values.phone, { type: 'UOC' }).then((res) => {
				console.log('res', res)
				const { uocList } = res
				if (!uocList || uocList.length === 0) {
					this.$message.warning('请联系管理员注册账号！')
					return
				}
				if (uocList.length === 1) {
					this.uocId = uocList[0]._id
					if (this.tabActiveKey === '1') {
						this.handleSubmit()
					} else {
						this.handleSubmit2()
					}
					return
				}
				this.uocList = uocList
				this.confirmUOCVisible = true
				return
			})
		},
		handleSubmit(e) {
			e && e.preventDefault()
			this.form.validateFields((err, values) => {
				if (err) {
					return
				}

				if (!this.uocId) {
					this.checkUOCList(values)
					return
				}

				this.loading = true
				delete values.remember
				values.pwd = md5(values.pwd)
				values.uocId = this.uocId
				values.type = 'UOC'
				login({ ...values })
					.then((res) => {
						this.loading = false
						localStorage.setItem('userInfo', JSON.stringify(res))
						setToken(res.token)
						setTimeout(() => {
							this.$router.push('/dashboard')
						}, 200)
					})
					.catch(() => {
						this.$message.warning('用户名或密码错误！')
						this.loading = false
					})
			})
		},
		handleSubmit2(e) {
			e && e.preventDefault()
			this.form1.validateFields((err, values) => {
				if (err) {
					return
				}
				if (!this.uocId) {
					this.checkUOCList(values)
					return
				}
				values.uocId = this.uocId

				this.loading = true
				delete values.remember
				values.captcha = md5(values.captcha)
				values.type = 'UOC'
				login({ ...values })
					.then((res) => {
						this.loading = false
						localStorage.setItem('userInfo', JSON.stringify(res))
						setToken(res.token)
						setTimeout(() => {
							this.$router.push('/dashboard')
						}, 200)
					})
					.catch(() => {
						this.$message.warning('用户名或密码错误！')
						this.loading = false
					})
			})
		},
		setPhoneNum(e) {
			this.phoneNum = e.target.value
		},
	},
	components: {
		userCard,
		sendSms,
	},
	beforeCreate() {
		this.form = this.$form.createForm(this)
		this.form1 = this.$form.createForm(this)
	},
}
</script>
<style lang="scss">
.login-page {
	#login-form .login-form-button {
		width: 100%;
	}
	.ant-divider {
		margin: 0px;
	}
	.login-pane {
		padding-top: 30px;
	}
	.ant-tabs-bar {
		border-bottom: 1px solid #eeeeee !important;
	}
}
</style>
