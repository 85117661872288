<template>
	<div v-if="userInfo">
		<a-list item-layout="horizontal" :data-source="dataSource">
			<a-list-item slot="renderItem" slot-scope="item, index">
				<a slot="actions" @click="listItemHandle(index)">修改</a>
				<a-list-item-meta
					:title="item.title"
					:description="item.description"
				>
				</a-list-item-meta>
			</a-list-item>
		</a-list>

		<a-modal
			:title="changeType === 0 ? '修改密码' : '修改手机号'"
			:visible="visible"
			@ok="modalOkHandle"
			@cancel="visible = false"
			:destroyOnClose="true"
			okText="确定"
			cancelText="取消"
		>
			<a-form :form="form">
				<a-form-item label="手机号">
					<a-input disabled="" v-decorator="['phone']"> </a-input>
				</a-form-item>
				<a-form-item label="验证码">
					<a-row :gutter="20">
						<a-col :span="16">
							<a-input
								size="large"
								v-decorator="[
									'captcha',
									{
										rules: [
											{
												required: true,
												message: '验证码不能为空',
											},
										],
									},
								]"
								placeholder="验证码"
							></a-input>
						</a-col>
						<a-col :span="8">
							<send-sms :phoneNum="phoneNum"></send-sms>
						</a-col>
					</a-row>
				</a-form-item>
				<a-form-item label="新密码" v-if="changeType === 0">
					<a-input v-decorator="['pwd']" placeholder="请输入新密码">
					</a-input>
				</a-form-item>

				<a-form-item label="新手机号" v-if="changeType === 1">
					<a-input
						@change="setPhoneNum"
						v-decorator="[
							'newPhone',
							{
								rules: [
									{
										required: true,
										message: '请输入必填项',
									},
								],
							},
						]"
					>
					</a-input>
				</a-form-item>
				<a-form-item label="验证码" v-if="changeType === 1">
					<a-row :gutter="20">
						<a-col :span="16">
							<a-input
								size="large"
								v-decorator="[
									'newCaptcha',
									{
										rules: [
											{
												required: true,
												message: '验证码不能为空',
											},
										],
									},
								]"
								placeholder="验证码"
							></a-input>
						</a-col>
						<a-col :span="8">
							<send-sms :phoneNum="newPhoneNum"></send-sms>
						</a-col>
					</a-row>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import sendSms from '@/components/user/sendSms'
import { updatePwd, updatePhone } from '@/api/user/login.js'
import { hideStr } from '@/utils/index'
import { setToken } from '@/api/auth'
import md5 from 'js-md5'

export default {
	data() {
		return {
			dataSource: [
				{ title: '账户密码', description: '当前密码强度：强' },
				{ title: '密保手机', description: '已绑定手机：138****8293' },
			],
			form: this.$form.createForm(this, { name: 'form' }),
			visible: false,
			changeType: 0,
			userInfo: null,
			phoneNum: null,
			newPhoneNum: null,
		}
	},
	components: {
		sendSms,
	},
	created() {
		try {
			const localUserInfo = localStorage.getItem('userInfo')
			this.userInfo = JSON.parse(localUserInfo)
			const { phone } = this.userInfo
			this.dataSource[1].description = `已绑定手机：${hideStr(
				phone,
				3,
				3
			)}`
		} catch (error) {
			console.log(error)
		}
	},
	methods: {
		setPhoneNum(e) {
			this.newPhoneNum = e.target.value
		},
		listItemHandle(index) {
			this.changeType = index
			this.visible = true
			this.phoneNum = this.userInfo.phone
			this.$nextTick(() => {
				this.form.setFieldsValue({
					phone: hideStr(this.userInfo.phone, 3, 3),
				})
			})
		},
		modalOkHandle() {
			this.form.validateFields((err, values) => {
				if (err) {
					return
				}
				const { captcha, pwd, newPhone, newCaptcha } = values
				const { _id, phone, type } = this.userInfo
				if (this.changeType === 0) {
					updatePwd({
						pwd: md5(pwd),
						captcha: md5(captcha),
						phone,
						type,
						userId: _id,
					}).then((res) => {
						localStorage.setItem('userInfo', JSON.stringify(res))
						setToken(res.token)
						location.reload()
					}).catch(err => {
						console.log(err);
						this.$message.warning('验证码错误！')
					})
				}else {
					updatePhone({
						phone,
						captcha: md5(captcha),
						newPhone,
						newCaptcha: md5(newCaptcha),
						type,
						userId: _id,
					}).then((res) => {
						localStorage.setItem('userInfo', JSON.stringify(res))
						setToken(res.token)
						location.reload()
					}).catch(err => {
						this.$message.warning('验证码错误！')
					})
				}
			})
		},
	},
}
</script>