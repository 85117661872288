<template>
	<div class="mine-content">
		<a-row style="height: 100%;" mode="vertical">
			<a-col :span="4" style="height: 100%;">
				<a-menu
					style="width: 100%;height: 100%;"
					mode="vertical"
                    v-model="selectedKeys"
					@click="handleClick"
				>
					<a-menu-item key="basicSettings">
                        <a-icon type="setting" />
						基本设置
					</a-menu-item>
					<a-menu-item key="securitySettings">
                        <a-icon type="safety" />
						安全设置
					</a-menu-item>
				</a-menu>
			</a-col>
			<a-col :span="20" style="height: 100%;">
                <div class="mc-router-view">
                    <router-view></router-view>
                </div>
            </a-col>
		</a-row>
	</div>
</template>

<script>
export default {
	data() {
        return {
            selectedKeys: ['basicSettings']
        }
    },
	methods: {
		handleClick(e) {
			console.log('click ', e)
            // this.selectedKeys = [e.key]
            this.$router.push(e.key)
		},
	},
}
</script>

<style lang="less" scoped>
.mine-content {
    height: 100%;
    background: white
   a
}
 .mc-router-view {
        padding: 12px;
        background: white;
        height: 100%;
    }
</style>