var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-page"},[_c('user-card',{attrs:{"title":"业委会注册"}},[_c('a-form',{staticClass:"register-form",attrs:{"id":"register-form","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address']),expression:"['address']"}],attrs:{"size":"large","field-names":{
						label: 'name',
						value: 'adcode',
						children: 'districts',
					},"options":_vm.cascaderOptions,"placeholder":"地址"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'communityName',
						{
							rules: [],
						} ]),expression:"[\n\t\t\t\t\t\t'communityName',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"size":"large","placeholder":"社区名称"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'UOCName',
						{
							rules: [
								{
									required: true,
									message: '业委会名称不能为空',
								} ],
						} ]),expression:"[\n\t\t\t\t\t\t'UOCName',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: '业委会名称不能为空',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"size":"large","placeholder":"业委会名称"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'userName',
						{
							rules: [
								{
									required: true,
									message: '用户姓名不能为空',
								} ],
						} ]),expression:"[\n\t\t\t\t\t\t'userName',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: '用户姓名不能为空',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"size":"large","placeholder":"用户姓名"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'pwd',
						{
							rules: [
								{
									required: true,
									message: '密码不能为空',
								} ],
						} ]),expression:"[\n\t\t\t\t\t\t'pwd',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: '密码不能为空',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"size":"large","placeholder":"6 - 16位密码，区分大小写","type":"password"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'confirm',
						{
							rules: [
								{
									required: true,
									message: '确认密码不能为空',
								},
								{
									validator: _vm.compareToFirstPassword,
								} ],
						} ]),expression:"[\n\t\t\t\t\t\t'confirm',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: '确认密码不能为空',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\tvalidator: compareToFirstPassword,\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"size":"large","placeholder":"确认密码","type":"password"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'phone',
						{
							rules: [
								{
									required: true,
									message: '手机号不能为空',
								} ],
						} ]),expression:"[\n\t\t\t\t\t\t'phone',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: '手机号不能为空',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"size":"large","placeholder":"请输入手机号（用作登录账号）"},on:{"change":_vm.setPhoneNum}})],1),_c('a-form-item',[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":16}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'code',
								{
									rules: [
										{
											required: true,
											message: '验证码不能为空',
										} ],
								} ]),expression:"[\n\t\t\t\t\t\t\t\t'code',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\tmessage: '验证码不能为空',\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],attrs:{"size":"large","placeholder":"验证码"}})],1),_c('a-col',{attrs:{"span":8}},[_c('send-sms',{attrs:{"phoneNum":_vm.phoneNum}})],1)],1)],1),_c('a-form-item',[_c('uploadFile',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'UOCRecordFile',
						{
							getValueFromEvent: _vm.uploadNormFile,
						} ]),expression:"[\n\t\t\t\t\t\t'UOCRecordFile',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tgetValueFromEvent: uploadNormFile,\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"item":{
						props: 'UOCRecordFile',
						uploadText: '业委会备案信息',
						maxUpload: 1,
					},"form":_vm.form}})],1),_c('a-form-item',[_c('a-button',{staticClass:"register-form-button",attrs:{"size":"large","type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("业委会注册")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }