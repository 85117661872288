<template>
  <div class="send-sms">
    <a-button size="large" @click="sendSmsHandle" style="width: 100%">{{
      btnName
    }}</a-button>
  </div>
</template>

<script>
import { sendSmsCaptcha } from "@/api/user/index";
export default {
  name: "",
  props: {
    phoneNum: {
      type: String,
    },
  },
  data() {
    return {
      timer: null,
      isRunning: false,
      startNum: 60,
      currentNum: 60,
    };
  },
  computed: {
    btnName() {
      return this.startNum === this.currentNum ? "获取验证码" : this.currentNum;
    },
  },
  methods: {
    countDown() {
      this.currentNum--;
      setTimeout(() => {
        if (this.currentNum > 0 && this.currentNum !== 60) {
          this.countDown();
        }
        if (this.currentNum === 0) {
          this.currentNum = 60;
        }
      }, 1000);
    },
    sendSmsHandle() {
      if (!this.phoneNum) {
        this.$message.error("请先填写手机号码");
        return;
      }
      if (this.currentNum !== this.startNum) {
        return;
      }
      this.countDown();

      sendSmsCaptcha({ phone: this.phoneNum }).then((res) => {
      });
    },
  },
};
</script>
<style lang="scss"></style>
