<template>
	<div class="basic-settings">
		<a-row>
			<a-col :span="16">
				<h4>基本设置</h4>
				<a-form
					id="login-form"
					:form="form"
					class="login-form"
					@submit="handleSubmit"
				>
					<a-form-item label="注册名称">
						<a-input
							size="large"
							v-decorator="[
								'username',
								{
									rules: [
										{
											required: true,
											message: '名称不能为空',
										},
									],
								},
							]"
							placeholder="输入注册名称"
						>
						</a-input>
					</a-form-item>
					<a-form-item label="职务">
						<a-input
							disabled
							size="large"
							v-decorator="[
								'jobTitle',
								{
									initialValue: computedJobTitle,
									rules: [],
								},
							]"
						>
						</a-input>
					</a-form-item>
					<a-form-item label="个人简介">
						<a-textarea
							:auto-size="{ minRows: 5, maxRows: 10 }"
							v-decorator="[
								'personalProfile',
								{
									rules: [],
								},
							]"
							placeholder="请填写个人简介"
						>
						</a-textarea>
					</a-form-item>
					<a-form-item>
						<a-button
							type="primary"
							html-type="submit"
							class="login-form-button"
							:loading="loading"
							size="large"
							>更新信息</a-button
						>
					</a-form-item>
				</a-form>
			</a-col>
			<a-col :span="8">
				<div class="bs-avatar">
					<a-avatar :size="128" :src="userInfo.avatar" />
					<div class="bs-a-btn">
						<a-button type="" @click="updateContract"
							>上传头像</a-button
						>
					</div>
				</div>
			</a-col>
		</a-row>
		<uploadFile
			:item="{}"
			style="display: none"
			ref="customer-upload"
			@changeHandle="upladHandle"
		></uploadFile>
	</div>
</template>

<script>
import uploadFile from '@/components/zform/components/zossUpload.vue'
import { updateUserInfo } from '@/api/user/index'
import { getUOCPositions } from '@/api/community/UOC'
export default {
	data() {
		return {
			form: this.$form.createForm(this),
			userInfo: {},
			loading: false,
			positions: [],
		}
	},
	components: { uploadFile },
	created() {
		try {
			const localUserInfo = localStorage.getItem('userInfo')
			this.userInfo = JSON.parse(localUserInfo)
		} catch (error) {
			console.log(error)
		}
		getUOCPositions().then((res) => {
			this.positions = res.list.map((item) => {
				return {
					text: item.name,
					value: item.value,
				}
			})
		})
	},
	mounted() {
		const { username, personalProfile } = this.userInfo
		console.log({ username, personalProfile })
		this.form.setFieldsValue({
			username,
			personalProfile,
		})
	},
	computed: {
		computedJobTitle() {
			const { position } = this.userInfo
			let finded = this.positions.find((item) => position === item.value)
			console.log(finded)
			if (finded) {
				return finded.text
			}
		},
	},
	methods: {
		updateContract() {
			this.$refs['customer-upload'].triggerUpload()
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (err) {
					return
				}
				try {
					const { personalProfile, username } = values
					await updateUserInfo(this.userInfo._id, {
						personalProfile,
						username,
					})
					this.userInfo.personalProfile = personalProfile
					this.userInfo.username = username
					localStorage.setItem(
						'userInfo',
						JSON.stringify(this.userInfo)
					)
					this.$message.success('更新成功！')
				} catch (error) {
					this.$message.warring('更新失败！')
					console.log('asad', error)
				}
			})
		},
		async upladHandle(result) {
			if (!result.url) {
				return
			}
			try {
				await updateUserInfo(this.userInfo._id, { avatar: result.url })
				this.userInfo.avatar = result.url
				localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
				this.$message.success('更新成功！')
			} catch (error) {
				console.log('asad', error)
				this.$message.warring('更新失败！')
			}
		},
	},
}
</script>

<style lang="less" scoped>
.basic-settings {
    background: white;
}
.bs-avatar {
	text-align: center;
	padding-top: 36px;
}
.bs-a-btn {
	margin-top: 12px;
}
</style>