var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic-settings"},[_c('a-row',[_c('a-col',{attrs:{"span":16}},[_c('h4',[_vm._v("基本设置")]),_c('a-form',{staticClass:"login-form",attrs:{"id":"login-form","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"注册名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'username',
							{
								rules: [
									{
										required: true,
										message: '名称不能为空',
									} ],
							} ]),expression:"[\n\t\t\t\t\t\t\t'username',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tmessage: '名称不能为空',\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"size":"large","placeholder":"输入注册名称"}})],1),_c('a-form-item',{attrs:{"label":"职务"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'jobTitle',
							{
								initialValue: _vm.computedJobTitle,
								rules: [],
							} ]),expression:"[\n\t\t\t\t\t\t\t'jobTitle',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\tinitialValue: computedJobTitle,\n\t\t\t\t\t\t\t\trules: [],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"disabled":"","size":"large"}})],1),_c('a-form-item',{attrs:{"label":"个人简介"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'personalProfile',
							{
								rules: [],
							} ]),expression:"[\n\t\t\t\t\t\t\t'personalProfile',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"auto-size":{ minRows: 5, maxRows: 10 },"placeholder":"请填写个人简介"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.loading,"size":"large"}},[_vm._v("更新信息")])],1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('div',{staticClass:"bs-avatar"},[_c('a-avatar',{attrs:{"size":128,"src":_vm.userInfo.avatar}}),_c('div',{staticClass:"bs-a-btn"},[_c('a-button',{attrs:{"type":""},on:{"click":_vm.updateContract}},[_vm._v("上传头像")])],1)],1)])],1),_c('uploadFile',{ref:"customer-upload",staticStyle:{"display":"none"},attrs:{"item":{}},on:{"changeHandle":_vm.upladHandle}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }