<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-11-01 12:42:28
-->
<template>
	<div class="user-card">
		<header v-if="showHeader">
			<span>{{ title }}</span>
			<a href="#" @click="goLogin">去登录</a>
		</header>
		<slot></slot>
		<div class="links-center">
			<a href="/privacyPolicy.html" target="_blank">隐私政策</a>
			<a href="/siteMap.html" target="_blank">站点地图</a>
			<a href="/aboutUs.html" target="_blank">关于我们</a>
			<br>
			  <a-divider style="color: #666;font-size: 12px;margin: 28px 0px;position: relative;">
				  <span style="top: 10px;z-index: 999;position: absolute;left: 50%;width: 100px;text-align: center;margin-left: -50px;">
					备案信息
				  </span>
				  
				</a-divider>
			<a href="https://beian.miit.gov.cn/#/Integrated/index" style="font-size: 12px" target="_blank">沪ICP备2020035086号-1</a>
			<br/>
			<a href="#" style="font-size: 12px">
				<img style="width: 18px;height: 18px;" src="https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/huhuiyun_gongan.png">
				沪公网备案 31010102007070
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	props: {
		header: {
			type: Boolean,
			default: true,
		},
		title: String,
	},
	data() {
		return {}
	},
	computed: {
		showHeader() {
			return this.header || false
		},
	},
	methods: {
		goLogin() {
			this.$router.push('/login')
		},
	},
}
</script>
<style lang="scss">
.user-card {
	padding: 12px 60px;
	background: #ffffff;
	box-shadow: 0px 6px 12px 0px rgba(5, 23, 51, 0.1);
	width: 480px;
	header {
		@include space-between;
		align-items: center;
		height: 42px;
		margin-bottom: 12px;
	}
}
</style>